import _ from 'lodash';

export class EnvService {
  static getLocalPort = () => 4446;
  static isLocalDev = () => _.get(window, 'location.port') === `${EnvService.getLocalPort()}`;

  static isDev = () => {
    return _.endsWith(window.location.hostname, 'dev');
  }

  static isProd = () => {
    return !EnvService.isDev() && !EnvService.isLocalDev();
  }
}